@import '/src/static/styles/theme';

.address {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px;

	&_ico {
		svg {
			width: 13px;
			height: 17px;
			path {
				fill:$colorPrimary;
			}
		}
	}

	&_id {
		font-weight: 500;
		font-size: 14px;
		color: $colorDark;
	}

	&_label {
		max-width: 295px;
		overflow: hidden;
    	text-overflow: ellipsis;
		font-weight: 300;
		font-size: 14px;
		color: $colorDark;
		flex-grow: 1;
	}

	&_disabled {

		input {
			pointer-events: none;
			color: $colorGreyLight;
		}

		svg {
			path {
				fill:$colorDark;
			}
		}
		div {
			color: $colorGreyLight;
		}
	}

	&_off {
		background-color: $colorGrey;

		svg {
			path {
				fill:$colorGreyLight;
			}
		}

		div {
			color: $colorGreyLight;
		}

		input {
			color: $colorGreyLight;
		}
	}

	&_input {
		max-width: 160px;
		width: 100%;
	}
}
