@import '/src/static/styles/theme';

.root {
	display: flex;
	align-items: center;
	background: $colorWhite;
	border-radius: 20px;
	justify-content: space-between;
	padding: 10px 20px;
	box-sizing: border-box;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	min-height: 60px;
}

.text {
	color: $colorDark;
	font-size: 14px;
	padding-right: 15px;
	box-sizing: border-box;
}

.button {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;

	span {
		width: 40px;
		height: 40px;
		min-width: 40px;
		background-color: $colorPrimaryYellow;
		border-radius: 100%;
		margin-right: 10px;
		color: $colorWhite;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&:hover span {
		background-color: $colorSecondaryYellow;
	}
}

.minifiedRoot {
	display: flex;
	justify-content: center;
	.button {
		flex-direction: column;
		position: relative;
		span {
			margin-top: 6px;
			margin-right: 0;
		}
	}
}

.promptWrap {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: calc(100% + 11px);
	z-index: 1;
	width: 370px;
}

.icoWrap {
	position: absolute;
	top: 11px;
	right: calc(100% + 25px);
}

.rightBar {
	display: flex;
	align-items: center;
}

.separator {
	margin: 0 10px 0 20px;
	width: 1px;
	height: 22px;
	background: $colorGreyLightThree;
}

.multilevel {
	display: flex;
}
