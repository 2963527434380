@import '/src/static/styles/theme';

.button {
	background: $colorGreyLight;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	width: 56px;
	height: 56px;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		transform: translateX(1px);
	}

	&:hover {
		background: $colorGreyLightHover;
	}
}
