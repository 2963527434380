@import 'static/styles/theme';

.selectedOptions {
	margin-right: 10px;
	background: #CCECE6;
	border-radius: 30px;
	height: 30px;
	padding: 0 12px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	color: #7E8490;
	font-weight: 500;
	font-size: 12px;
	margin-top: 10px;
	position: relative;
	span {
		cursor: pointer;
		white-space: nowrap;
	}
	button {
		background: none;
		color: #7E8490;
		padding: 0;
		border: none;
		font-size: 0;
		margin-left: 8px;
		cursor: pointer;
		width: 12px;
		svg {
			width: 100%;
		}
	}
}

.selectedOptionGroup {
	background: $colorWhite;
	position: absolute;
	top: 117%;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 20px;
	filter: drop-shadow(-4px 4px 14px rgba(78, 69, 92, 0.15));
	min-width: 340px;
	max-width: 400px;
	z-index: 1;
	padding: 20px 20px 20px 18px;
	box-sizing: border-box;
}
