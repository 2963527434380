@import '/src/static/styles/theme';

.wrap {
	color: $colorError;
	font-weight: 400;
	font-size: 12px;
	white-space: nowrap;
	margin-right: 20px;
	display: flex;
	align-items: center;

	svg {
		width: 18px;
		margin-right: 10px;
	}

	span {
		width: 20px;
		margin-left: 5px;
	}
}
