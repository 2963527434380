@import '/src/static/styles/theme';

.address {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px;

	&_ico {
		svg {
			width: 13px;
			height: 17px;
			path {
				fill:$colorPrimary;
			}
		}
	}

	&_id {
		font-weight: 500;
		font-size: 14px;
		color: $colorDark;
	}

	&_label {
		font-weight: 300;
		font-size: 14px;
		color: $colorDark;
		flex-grow: 1;
	}

	&_disabled {
		svg {
			path {
				fill:$colorDark;
			}
		}
		div {
			color: $colorGreyLight;
		}
	}

	&_off {
		background-color: $colorGreyLightTwo;
		pointer-events: none;
		svg {
			path {
				fill:$colorGreyLight;
			}
		}
		div {
			color: $colorGreyLight;
		}
	}
}
