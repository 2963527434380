@import '/src/static/styles/theme';

.root {
	position: relative;
	font-size: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		border-radius: 20px;
		width: 90px;
		height: 90px;
		object-fit: cover;
	}
}

.container {
	position: relative;
}

.changeImg {
	background: #493f588a;
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 20px;
	bottom: 0px;
	right: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0;
	flex-direction: column;
	gap: 5px;

	&_edit {
		font-size: 12px;
		color: $colorWhite;
	}

	svg {
		path {
			fill: $colorWhite;
		}
	}

	&:hover {
		opacity: 1;
	}
}

.empty {
	width: 100%;
	padding-top: 100%;
	border: 1px dashed $colorGreyLight;
	box-sizing: border-box;
	border-radius: 20px;
	cursor: pointer;
	position: relative;
}

.emptyBody {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: $colorGreyLight;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	span {
		font-size: 10px;
		margin-top: 10px;
	}
}

.promptWrap {
	position: absolute;
	z-index: 1;
	width: 320px;
	left: 50%;
	transform: translateX(-50%);
	top: calc(100% + 18px);
}

.promptIco {
	position: absolute;
    left: 50%;
    top: 50%;
}

.disabled {
	filter: grayscale(100%);
}
