@import '/src/static/styles/theme';

.title {
	font-weight: bold;
	font-size: 16px;
	color: $colorDark;
	margin: 40px 0 15px;
	padding-left: 20px;
}

.addSectionWrap {
	margin-top: 40px;
	margin-bottom: 20px;
	&.addSectionWithCollection {
		padding-right: 30px;
		box-sizing: border-box;
	}
}

.sortProductIdent {
	top: 12px;
	right: 18px;
}

.addCollectionWrap {
	box-sizing: border-box;
	padding: 20px 0 30px;
}
