@import '/src/static/styles/theme';

.root {
	box-sizing: border-box;
	position: relative;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 4px 4px 4px 5px;
		position: absolute;
		top: -4px;
		left: -5px;
	}
}

.label {
	display: flex;
	box-sizing: border-box;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	padding: 0 10px;

	&_available {
		padding: 0 0 0 5px;
	}

	&_title {
		font-weight: 500;
		font-size: 14px;
		color: $colorGreyLight;
		white-space: nowrap;
	}

	&_ico {
		svg {
			path {
				fill:$colorGreyLight
			}
		}
	}

	&_counter {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 5px 8px;
		width: 36px;
		height: 26px;
		border-radius: 15px;
		font-weight: 500;
		font-size: 12px;
		border: 1px solid $colorPrimary;
		color: $colorPrimary;

		&_low {
			border: 1px solid $colorSecondaryYellow;
			color: $colorSecondaryYellow;
		}

		&_empty {
			border: 1px solid $colorError;
			color: $colorError;
		}

	}
}

.addressList {
	width: 440px;
	display: flex;
	min-width: 220px;
	min-height: 116px;
	position: absolute;
	top: 35px;
	right: -110px;
	color: $colorGreyLight;
	background-color: $colorWhite;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;
	padding: 20px;
	z-index: 12;
	flex-direction: column;
	box-sizing: border-box;

	&_title {
		font-weight: 600;
		font-size: 14px;
		color: $colorDark;
		margin-bottom: 7px;
	}

	&_info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 300;
		font-size: 12px;
		color: $colorGreyLight;
	}

	&_options {
		display: flex;
		flex-direction: column;
		margin-top: 7px;
		gap: 2px;

		&.needsOptimization {
			height: 180px;
			overflow: auto;
			&.onlyOneCollection {
				height: 75px;
			}
		}
	}
	&.toLeft {
		right: 0;
	}
}

.addressListOverlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 12;
}

@media screen and (max-width: 1400px) {
	.label {
		padding: 0;
	}
}
