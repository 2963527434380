@import '/src/static/styles/theme';

.root {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: 10px;
	padding: 10px 13px 10px 10px;
	box-sizing: border-box;
	background: #fff;
	position: relative;
	transition: background 0.5s ease-in-out;
	border-radius: 10px;
	&::after,
	&::before {
		content: '';
		display: block;
		width: calc(100% - 20px);
		height: 1px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		background: $colorGreyLightThree;
		border-radius: 1px;
	}
	&::before {
		bottom: auto;
		top: 100%;
	}
}

.disabled {
	background-color: $colorGrey;
	transition: background 0.5s ease-out;
	border-radius: 0;
	position: relative;

	&::before {
		content: '';
		width: 10px;
		height: 100%;
		background-color: $colorGrey;
		position: absolute;
		top: 0;
		left: -5px;
	}

	&::after {
		content: '';
		width: 10px;
		height: 100%;
		background-color: $colorGrey;
		position: absolute;
		top: 0;
		left: calc(100% + 5px);
	}

	.toggleActive {
		pointer-events: all;
	}
}

.imgWrap {
	margin-right: 20px;
	width: 90px;
	min-width: 90px;
	font-size: 0;
}

.productNameWrap {
	width: 28%;
}

.productPriceWrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 14px;
	box-sizing: border-box;
}
.productChangeWrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
}

.productInfo {
	width: 67%;
	margin-left: 20px;
	margin-right: 25px;
}

.productPriceWrap {
	color: #797A8D;
	font-weight: 500;
	font-size: 14px;
}

.sortIdentifierWrap {
	margin-left: 20px;
}

.productIngradient {
	display: flex;
	align-items: center;
}

.productRemove {
	margin: 20px 0 0 21px;
	color: $colorGreyLight;
	cursor: pointer;
	position: relative;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 38px;
		height: 38px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.productName {
	input {
		font-size: 16px;
		font-weight: 700;
	}
}

.toggleDis {
	pointer-events: none;
	& > span { // styles for switch component
		background: rgba(121, 122, 141, 0.5);
		& > span {
			background: #E2E2E6;
			box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
		}
	}
}

.productOptionsWrap {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.differentPrice {
	display: flex;
	white-space: nowrap;
	padding-top: 20px;
	margin-right: 18px;
	align-items: center;
}

.inputWrap {
	position: relative;
	width: 100%;
}

.titlesLangBarWrap {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
}

.descriptionInput {
	input {
		font-weight: normal;
	}
}

.externalIdWrap {
	margin-right: 10px;
	min-width: 235px;
	width: 235px;
}

.priceMulti {
	margin-left: 10px;
}

.checkBoxWrap {
	position: absolute;
	right: calc(100% + 25px);
	top: 47px;

	&.onlySections {
		position: static;
		transform: none;
		margin: 40px 16px 0 0;
	}
}

.checkBoxIco {
	margin-right: 0;
	width: 15px;
	height: 15px;
	flex-basis: auto;

	svg {
		height: 15px;
	}
}

@media screen and (max-width: 1500px) {
	.productNameWrap {
		width: 34%;
	}
}
