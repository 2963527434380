@import '/src/static/styles/theme';

.root {
	margin-left: 15px;
	position: relative;
}

.ico {
	color: $colorGreyLight;
	cursor: pointer;
	position: relative;
	font-size: 0;

	&.attention::after {
		content: '';
		display: block;
		background: $colorError;
		width: 8px;
		height: 8px;
		position: absolute;
		border-radius: 100%;
		right: -6px;
		top: -6px;
	}

	&:hover::before {
		content: '';
		display: block;
		background: $colorGreyLight;
		pointer-events: none;
		width: 38px;
		height: 38px;
		position: absolute;
		z-index: 1;
		opacity: 0.05;
		border-radius: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.popupBody {
	position: absolute;
	padding: 40px 30px 30px;
	box-sizing: border-box;
	color: $colorDark;
	background: $colorWhite;
	box-shadow: -2px 2px 12px rgba(78, 69, 92, 0.2);
	border-radius: 20px;
	width: 419px;
	left: -9px;
	bottom: calc(100% + 28px);
}

.title {
	font-weight: 600;
	font-size: 18px;
	text-align: center;
}

.list {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    padding-left: 55px;
    margin: 10px 0 20px;

	.listItem {
		position: relative;

		&::after {
			content: '';
			display: block;
			background: $colorDark;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: -13px;
			width: 3px;
			height: 3px;
			border-radius: 100%;
		}
	}
}

.text {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	margin-top: 28px;
	letter-spacing: 0.01em;

	svg {
		margin-left: 5px;
		transform: translateY(3px);
	}
}

.closeButton {
	color: $colorGreyLight;
	position: absolute;
    right: 20px;
    top: 20px;
	cursor: pointer;

	svg {
		display: block;
		width: 16px;
		height: 16px;
	}

	&:hover::after {
		content: '';
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 38px;
		height: 38px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.table {
	border: 1px solid $colorGreyLightFour;
	border-radius: 8px;
	overflow: hidden;
	margin-top: 28px;
}

.tableHeader {
	background: $colorGrey;
	border-bottom: 1px solid $colorGreyLightFour;
	display: flex;
	align-items: center;
}

.tableHeaderColl {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.01em;
	padding: 12px 0;
	box-sizing: border-box;

	svg {
		color: #22BE4E;
		margin-right: 12px;
	}

	&.cannot svg {
		color: $colorError;
	}

	&:first-child {
		border-right: 1px solid $colorGreyLightFour;
	}
}

.tableBody {
	display: flex;
	height: 55px;

	&.border {
		border-top: 1px solid $colorGreyLightFour;
	}
}

.tableBodyColl {
	display: flex;
	align-items: center;
	width: 50%;
	justify-content: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	text-align: center;

	&:first-child {
		border-right: 1px solid $colorGreyLightFour;
	}
}

.overlay {
    position: fixed;
    width: 100vw;
    height: 100vw;
    left: 0;
    top: 0;
    z-index: 10;
}

@media screen and (max-width: 1500px) {
	.popupBody {
		left: auto;
    	right: -9px;
	}
}
