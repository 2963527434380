@import '/src/static/styles/theme';

.root {
	padding: 8px 15px;
	&.active {
		color: $colorDark;
		background: $colorGrey;
		.listContainer_option_name {
			color: $colorPrimary;
		}
		.listContainer_option_storeId {
			color: $colorPrimary;
		}
	}

}

.container {
	width: 100%;
	display: flex;
	justify-content: space-between;

	.listContainer_option {
		display: flex;
		color: $colorGreyLight;
		cursor: pointer;
		gap: 10px;
		align-items: baseline;
		box-sizing: border-box;
		font-Size: 14px;
		position: relative;

		&.active {
			color: $colorDark;
			background: $colorGrey;
			.listContainer_option_name {
				color: $colorPrimary;
			}
		}

		&_info {
			display: grid;
			overflow: hidden;
		}

		&_storeId {
			display: inline-block;
			font-weight: 500;
			font-size: 14px;
		}

		&_name {
			font-weight: 500;
			color: $colorDark;
		}

		&_address {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		&_button {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			max-height: 24px;
			margin-top: -4px;
			gap: 10px;
			background-color: #FFF3DA;
			padding: 4px 6px;
			border-radius: 12px;
			font-size: 12px;
			cursor: pointer;
			white-space: nowrap;

			.openItem {
				transform: rotate(180deg);
			}
		}
	}

}

.store_address {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	position: relative;

	&_item {
		display: flex;
		gap: 10px;
		padding: 10px 0px 10px 50px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		position: relative;
	}

	&_item:first-child:after {
		width: 20px;
		height: 1px;
		background-color: $colorGreyLightFour;
		position: absolute;
		top: 27.5px;
		left: 9px;
		transform: translate(0%, -50%) rotate(90deg);
	}

	&_item:last-child:after {
		width: 20px;
		height: 1px;
		background-color: $colorGreyLightFour;
		position: absolute;
		top: 10px;
		left: 9px;
		transform: translate(0%, -50%) rotate(90deg);
	}

	&_item::before {
		content: '';
		width: 20px;
		height: 1px;
		background-color: $colorGreyLightFour;
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translate(0%, -50%);
	}


	&_item::after {
		content: '';
		width: 38px;
		height: 1px;
		background-color: $colorGreyLightFour;
		position: absolute;
		top: 50%;
		left: 0px;
		transform: translate(0%, -50%) rotate(90deg);
	}

}
.listContainer_option_storeId_items {
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
}

.listContainer_option_address {
	font-weight: 500;
	font-size: 14px;
	color: $colorDark;
	text-overflow: ellipsis;
	overflow: hidden;
}
