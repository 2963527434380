@import '/src/static/styles/theme';

.openPopupButton {
	font-size: 0;
	color: $colorGreyLight;
	cursor: pointer;
	position: relative;

	&:hover {
		&::after {
			content: "";
			display: block;
			position: absolute;
			width: 32px;
			height: 32px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: $colorGreyLight;
			opacity: 0.05;
			border-radius: 100%;
		}
	}

	&.redCircle {
		&::before {
			content: "";
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			background: $colorError;
			top: 0;
			right: -1px;
		}
	}
}
