@import '/src/static/styles/theme';

.link {
	color: $colorDark;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	text-decoration: none;
	font-weight: 500;
	font-size: 12px;
    box-sizing: border-box;
    padding: 2px 0;
	margin-top: 45px;
	text-align: center;
	cursor: pointer;
	span {
		margin-bottom: 8px;
	}
	&:first-child {
		margin-top: 0;
	}
	&.active {
		&::after {
			content: '';
			display: block;
			width: 4px;
			height: 100%;
			top: 0;
			left: 0;
			background: $colorPrimaryYellow;
			position: absolute;
			border-radius: 0px 4px 4px 0px;
		}
		span {
			color: $colorPrimaryYellow;
		}
	}
}
