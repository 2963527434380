@import '/src/static/styles/theme';

.title {
	font-weight: 700;
	font-size: 16px;
	border-bottom: 1px solid $colorGreyLightThree;
	padding-bottom: 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;

	&.isSectionsTitle {
		border: none;
		padding-bottom: 0;
		margin-top: 20px;
	}
}
