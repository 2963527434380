
@import 'static/styles/_theme.scss';

.checkbox {
	display: flex;
	align-items: center;
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.checkboxIcon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;
	flex: 0 0 18px;
	border: 2px solid $colorLight;
	border-radius: 2px;
	margin-right: 10px;

	&_active {
		border: 0;
		svg {
			color: $colorPrimary;
			width: 100%;
			flex: 0 0 100%;
		}
	}
}

.checkboxText {
	font-size: 14px;
	color: $colorDark;
}

.selectOption {
	font-size: 14px;
	display: flex;
	align-items: center;
	padding: 10px 30px;
	cursor: pointer;
	&:hover, &_active {
		background-color: $colorGrey;
	}
	&_active {
		color: $colorPrimary;
	}
	&_onlyView {
		pointer-events: none;
	}
}

.selectCheckboxRoot {
	width: 100%;
}

.selectCheckboxText {
	width: calc(100% - 28px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	svg {
		width: 24px;
		flex: 0 0 24px;
		color: $colorLight;
	}
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	button {
		font-size: 12px;
		background: none;
		border: none;
		outline: none;
		color: $colorPrimary;
		text-transform: uppercase;
		cursor: pointer;
		font-weight: 500;
	}
	.buttonCancel {
		color: $colorLight;
	}
}

.root, .inner {
	position: relative;
}

.label {
	font-size: 12px;
	color: $colorLight;
	padding: 0 0 5px 20px;
}

.control {
	border: 1px solid $colorGreyLightThree;
	box-sizing: border-box;
	border-radius: 30px;
	margin: 0 10px 0 25px;
	height: 40px;
	outline: none;
	color: $colorDark;
	font-size: 14px;
	transition: 0.3s border-color;
	&::placeholder, &_empty {
		color: $colorLight;
	}
	&:disabled {
		background: $colorGrey;
		pointer-events: none;
	}
	&:focus,&.focus {
		border-color: $colorLight;
	}
	&.error {
		border-color: $colorError;
	}
}

.disabled {
	.control {
		pointer-events: none;
		background: $colorGrey;
	}

	.icon {
		pointer-events: none;
		color: rgba($colorLight, 0.5);
	}
}

.controlSelect {
	display: flex;
	align-items: center;
	cursor: pointer;
	span {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-right: 10px;
	}
}

.error {
	color: $colorError;
}

.dropDownSelect {
	position: absolute;
	top: 100%;
	margin-top: 2px;
	left: 50%;
	transform: translateX(-50%);
	background: #FFFFFF;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;
	min-width: 100%;
	z-index: 3;
	box-sizing: border-box;
	padding: 15px 0 5px;
	max-height: 450px;
	overflow: auto;
}

.errorText {
	font-size: 12px;
	color: $colorError;
	margin-top: 5px;
	text-align: left;
	padding-left: 20px;
}
