@import '/src/static/styles/theme';

.addLangButton {
	padding: 7px 10px;
	box-sizing: border-box;
	font-size: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 15px;
	&:hover {
		background: rgba(0, 160, 130, 0.05);
	}
	span {
		color: $colorPrimary;
		margin-right: 7px;
	}
}

.langDropDownWrap {
	position: absolute;
	top: calc(100% + 5px);
	left: 50%;
	width: 240px;
	margin-left: -120px;
}
