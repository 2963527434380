@import '/src/static/styles/theme';


.wrapper {
	background: $colorWhite;
	width: 960px;
	border-radius: 20px;
}

.headerline {
	padding: 15px 56px 15px 30px;
	box-shadow: -2px 2px 8px 0px #4E455C14;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	h3 {
		margin: 0;
		font-weight: 700;
		font-size: 18px;
		color: $colorDark;
	}
}

.group {
	margin-top: 0;
}

.btns {
	display: flex;
	justify-content: space-between;
	padding: 0 20px 20px;

	button {
		background: none;
		outline: none;
		border: none;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 12px;
		color: $colorPrimary;
		padding: 0 10px;
		cursor: pointer;
		position: relative;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}
	}

	button:first-child {
		color: $colorGreyLight;

		&:hover::after {
			background: rgba(0, 160, 130, 0.05);
		}
	}
}

.contentClass {
	overflow: visible;
}

.listWrapClass {
    max-height: 210px;
    overflow-y: auto;
}
