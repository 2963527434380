@import '/src/static/styles/theme';

.listWrap {
	margin-top: 10px;
	position: relative;
	width: calc(100% + 40px);
	margin-left: -20px;

	&::after {
		content: '';
		display: block;
		width: calc(100% - 40px);
		height: 1px;
		background: $colorGreyLightThree;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}
}

.addOption {
	margin-top: 17px;
	display: flex;
	justify-content: center;
}

.errorValidation {
	padding: 10px 0 0 30px;
}
