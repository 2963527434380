@import '/src/static/styles/theme';

.root {
	background: #FFFFFF;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;
	width: 480px;
	color: $colorDark;
}

.header {
	padding: 20px 75px 20px;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 18px;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	text-align: center;
	line-height: 26px;
}

.body {
	padding: 20px 30px 25px;
	box-sizing: border-box;
}

.description {
	font-weight: 400;
	font-size: 14px;
	text-align: center;
}

.movingPanel {
	border-radius: 20px;
	border: 1px solid #D8D9E4;
	padding: 20px 16px 5px;
	box-sizing: border-box;
}

.movingPanelTitle {
	font-weight: 600;
	font-size: 14px;
}

.itemsForMoveWrap {
	margin-top: 10px;
    overflow-y: auto;
    max-height: 283px;
}

.itemForMove {
	display: flex;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid #D8D9E4;
	padding: 8px 13px 8px 15px;
}

.itemForMoveImg {
	width: 30px;
	height: 30px;
	min-width: 30px;
	border-radius: 100%;
}

.itemForMoveName {
	margin-left: 10px;
	font-weight: 600;
	font-size: 14px;
}

.checkedStatus {
	width: 14px;
	height: 14px;
	min-width: 14px;
	border-radius: 100%;
	border: 2px solid $colorGreyLight;
	margin-left: auto;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;

	&.checked {
		border-color: $colorPrimary;
		position: relative;

		&::after {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			background: $colorPrimary;
		}
	}
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 35px;

	span {
		cursor: pointer;
		font-size: 12px;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 0.04em;
		font-weight: 500;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}

		&.save {
			color: $colorPrimary;

			&:hover::after {
				background: rgba(0, 160, 130, 0.05);
			}

			&.disable {
				pointer-events: none;
				color: $colorGreyLight;
			}
		}
	}
}

.arr {
	color: $colorGreyLight;
	margin-left: auto;

	&.rotate {
		transform: rotate(180deg);
	}
}

.sectionForMove {
	padding: 15px 12px 15px 15px;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.sectionsWrap {
	border-bottom: 1px solid #D8D9E4;
}

.sectionForMoveTitle {
	font-weight: 500;
	font-size: 14px;
}

.emptyImg {
	width: 30px;
	height: 30px;
	min-width: 30px;
	border-radius: 100%;
	background: $colorGreyLightTwo;
	color: #AEAEB8;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 16px;
	}
}
