@import '/src/static/styles/theme';

.button {
	margin: 0 30px 0 18px;
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.buttonMenuLanguages {
	font-weight: 500;
	font-size: 14px;
	margin: 0 10px 0 6px;
}

.ico {
	color: $colorGreyLight;
	background: #D8D9E4;
	width: 30px;
	min-width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.root {
	position: relative
}
