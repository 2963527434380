@import '/src/static/styles/theme';

.root {
	background: $colorWhite;
	border-radius: 20px;
	box-sizing: border-box;
	padding: 20px 10px 23px;
}

.addProductButtonWrap {
	display: flex;
	justify-content: center;
	margin: 25px 30px 0 0;
	position: relative;
}

.errorValidation {
	padding: 0 0 0 30px;
}

.collapse {
	padding-bottom: 10px;
	&.groupsChildHaveErrs {
		background: $colorErrorLight;
	}
}

.sortProductIdent {
	right: 30px;
    top: 10px;
}

.isNotFirstProd {
	div[data-id=promptWrap] {
		display: none!important;
	}
}
