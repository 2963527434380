@import '/src/static/styles/theme';

.root {
	padding: 0 10px 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	margin-bottom: 10px;
}

.headInput {
	input {
		font-weight: bold;
		font-size: 16px;
	}
}

.inputWrap {
	flex-grow: 1;
	margin-left: 15px;
	max-width: 350px;
}

.productCount {
	color: $colorGreyLight;
	font-size: 14px;
	margin-left: 15px;
	box-sizing: border-box;
	position: relative;
	margin-top: 18px;
	transform: translateY(-50%);
	margin-right: 15px;
    white-space: nowrap;
}

.changeInformWrap {
	display: flex;
	align-items: flex-start;
	width: calc(49% + 130px);
	& > span {
		margin-top: 20px;
		transform: translateY(-50%);
	}
}

.isOptionsPage {
	padding-left: 0;
	padding-right: 5px;

	.inputWrap {
		margin-left: 0;
		max-width: 400px;
	}

	.right,
	.productRemove {
		margin-right: 0;
	}
}

.right {
	display: flex;
	align-items: center;
	margin-right: 30px;
}

.productRemove {
	display: flex;
	justify-content: center;
	margin: 0 20px;
	color: $colorGreyLight;
	cursor: pointer;
	width: 20px;
	height: 20px;
	padding: 5px;
	position: relative;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 38px;
		height: 38px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.toggleActive {
	pointer-events: all;
}

.collapseSectionArr {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $colorGreyLight;
	font-size: 0;
    margin-top: 5px;
	cursor: pointer;
	width: 20px;
	height: 20px;
	padding: 5px;
	position: relative;

	&.rotate {
		transform: rotate(-90deg);
	}

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.addProductButtonWrap {
	margin-left: auto;
	margin-right: 15px;
	margin-top: 9px;
}

.toggleDis {
	pointer-events: none;
	& > span { // styles for switch component
		background: rgba(121, 122, 141, 0.5);
		& > span {
			background: #E2E2E6;
    		box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
		}
	}
}

.inputWrap {
	position: relative;
}

.titlesLangBarWrap {
	position: absolute;
	top: 11px;
	right: 20px;
}

.checkBoxWrap {
	position: absolute;
	right: calc(100% + 25px);
	top: 50%;
	transform: translateY(-50%);

	&.onlySections {
		position: static;
		transform: none;
		margin: auto 15px auto 0;
	}
}

.checkBoxIco {
	margin-right: 0;
	width: 15px;
	height: 15px;
	flex-basis: auto;

	svg {
		height: 15px;
	}
}
