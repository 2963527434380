@import '/src/static/styles/theme';

.root {
	border-bottom: 1px solid $colorGreyLightThree;
	box-sizing: border-box;
	overflow: hidden;
	min-height: 60px;
	position: relative;
}

.children {
	padding-left: 30px;
	box-sizing: border-box;
	transition: all 0.7s ease;
	overflow: auto;
	margin-top: 0;
	&.animCollapse {
		margin-top: -80%;
	}
}

