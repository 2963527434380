@import '/src/static/styles/theme';

.root {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #E9E9EC;
	backdrop-filter: blur(4px);
}

.button {
	height: 42px;
	padding: 0 20px;
	box-sizing: border-box;
	background: #F6F6F9;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 21px;
	color: $colorDark;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.46px;
	cursor: pointer;
	display: flex;
	align-items: center;

	&:hover {
		background: $colorGrey;
	}

	&.invert {
		background: $colorGreyLight;
		color: $colorWhite;
		margin-left: 16px;

		svg {
			margin-right: 10px;
		}

		&:hover {
			background: #6F7087;
		}
	}

	&.disable {
		opacity: 0.5;
		pointer-events: none;
	}
}
