@import '/src/static/styles/theme';

.root {
	color: $colorBlack;
	width: 556px;
	padding: 40px 36px 25px;
	box-sizing: border-box;
	box-shadow: -2px 2px 12px rgba(78, 69, 92, 0.2);
	border-radius: 20px;
	background: $colorWhite;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ico {
	margin-bottom: 25px;
}

.title {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
}

.text {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	letter-spacing: 0.01em;
	margin-top: 15px;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 22px;
	width: 100%;

	button {
		background: none;
		border: none;
		outline: none;
		color: $colorGreyLight;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 12px;
		cursor: pointer;
		padding: 0;
		position: relative;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}
	}
}

button.remove {
	color: $colorPrimary;
	position: relative;

	&:hover::after {
		background: rgba(0, 160, 130, 0.05);
	}
}
