@import '/src/static/styles/theme';

.root {
	padding-left: 15px;
	margin-left: 15px;
	box-sizing: border-box;
	position: relative;
	&::after {
		content: '';
		display: block;
		background: #D8D9E4;
		width: 1px;
		height: 22px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	&.isCollapseAll{
		.collapseAllButton {
			color: $colorGreyLight;
			pointer-events: none;
			span {
				color: $colorGreyLight;
			}
		}
	}
}

.collapseAllButton {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: $colorDark;
	font-weight: 500;
	font-size: 14px;
	position: relative;

	span {
		color: $colorPrimary;
		margin-right: 10px;
		transform: rotate(180deg);
		display: block;
	}

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(0, 160, 130, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 6px 8px 6px 10px;
		position: absolute;
		top: -6px;
		left: -10px;
	}
}
