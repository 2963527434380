@import 'static/styles/theme';

.root {
	width: 647px;
	border-radius: 20px;
	padding: 40px 30px;
	box-sizing: border-box;
	background: $colorWhite;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ico {
	background: #FFF3DA;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $colorError;
}

.title {
	font-weight: 700;
	font-size: 18px;
	margin-top: 20px;
}

.text {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	margin-top: 15px;
	text-align: center;
}

.structureWrap {
	padding: 0 23px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
}

.levelsWrap {
	width: 240px;
	border-radius: 20px;
	background: $colorGrey;
	padding: 15px 20px;
	box-sizing: border-box;
	&.nextLevelsWrap {
		background: #F5FAFF;
		.structureTitle {
			color: $colorPrimary;
		}
	}
}

.structureTitle {
	color: $colorGreyLight;
	font-weight: 500;
	font-size: 14px;
}

.structurePreview {
	margin-top: 10px;
	display: flex;
	align-items: center;
	&.structurePreviewCollections {
		span {
			background: #CCECE6;
		}
	}
	span {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 12px;
		box-sizing: border-box;
		border-radius: 16px;
		font-weight: 500;
		font-size: 14px;
		background: $colorGreyLightThree;
	}
}

.arr {
	color: $colorGreyLight;
	margin-right: 14px;
}

.twoStructWrap {
	display: flex;
	justify-content: center;
}

.threeStructWrap {
	display: flex;
	justify-content: flex-end;
}

.separator {
	width: 17px;
	font-size: 0;
	color: $colorGreyLight;
	margin: 0 25px;
	transform: rotate(-90deg);
	svg {
		width: 100%;
		height: 100%;
	}
}

.buttonsWrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 30px;

	button {
		border: none;
		outline: none;
		background: none;
		text-transform: uppercase;
		color: $colorGreyLight;
		font-weight: 500;
		font-size: 12px;
		text-align: center;
		cursor: pointer;
		position: relative;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}

		&.changeStructureView {
			color: $colorPrimary;

			&:hover::after {
				background: rgba(0, 160, 130, 0.05);
			}
		}
	}
}
