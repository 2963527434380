@import '/src/static/styles/theme';

.root {
	background: $colorWhite;
	position: sticky;
	top: 0;
	width: 80px;
	height: 100vh;
	border-radius: 0px 30px 30px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 12;
}

.logo {
	color: $colorPrimaryYellow;
	position: absolute;
	top: 16px;
	left: 50%;
	transform: translateX(-50%);
}

.logoutMenu {
	color: $colorDark;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 32px;
}
