@import '/src/static/styles/theme';

.popupUploadImg {
	background: $colorWhite;
	border-radius: 20px;
	filter: drop-shadow(-4px 4px 14px rgba(78, 69, 92, 0.15));
	width: 520px;
}

.title {
	padding: 20px 30px 18px;
	box-sizing: border-box;
	font-weight: bold;
	font-size: 18px;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
}

.popupUploadBody {
	padding: 20px 30px 30px;
	box-sizing: border-box;
}

.uploadWrap {
	display: flex;
	justify-content: center;
	align-items: flex-start	;
}

.imgWrap {
	width: 300px;
	height: 300px;
	background: $colorGrey;
	border: 1px dashed $colorGreyLight;
	box-sizing: border-box;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: $colorDark;
	position: relative;
	overflow: hidden;
	span {
		color: $colorGreyLight;
		svg {
			width: 40px;
			height: 36px;
		}
	}
}
.imgWrapLoad {
	background:$colorWhite;
}

.text {
	text-align: center;
	color: $colorDark;
	font-size: 14px;
	margin-top: 12px;
	line-height: 24px;
}

.uploadButton {
	letter-spacing: 0.46px;
	font-weight: 500;
	font-size: 14px;
	color: $colorWhite;
	background: $colorPrimary;
	width: 180px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 21px;
	margin-top: 8px;
	cursor: pointer;

	&:hover {
		background: $colorPrimaryHover;
	}
}
.noneInput {
	display: none;
}
.loadedImg {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 10px;
}

.infoWrap {
	color: $colorDark;
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	margin-top: 10px;
	max-width: 300px;
	background: #EDF5FC;
	padding: 6px 12px;
	box-sizing: border-box;
	margin-left: auto;
	border-radius: 8px;
	line-height: 18px;
	span {
		font-weight: 500;
	}
	svg {
		width: 20px;
		height: 20px;
		min-width: 20px;
		margin-right: 7px;
		color: #94ADC6;
	}
	.infoWrapText {
		div {
			margin-bottom: 7px;
		}
	}
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 22px;

	button {
		background: none;
		border: none;
		outline: none;
		color: $colorGreyLight;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 12px;
		cursor: pointer;
		padding: 0;
		position: relative;

		&.saveButton {
			color: $colorPrimary;
		}

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}
	}
}

.promptIco {
	position: absolute;
	top: 50%;
	left: 50%;
}

.error {
	width: 100% !important;
    text-align: center;
    margin: 10px auto 0;
    transform: none !important;
    background: none;
    white-space: normal !important;
    box-sizing: border-box;
}

.rootValidation {
	max-width: 300px;
}

.miniPreview {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
	position: relative;
}

.miniPreviewText {
	font-weight: 400;
	font-size: 14px;
	margin-top: 10px;
	color: $colorDark;
}

.miniPreviewImg {
	width: 120px;
	height: 120px;
	border-radius: 10px;
	position: relative;
	border: 1px dashed $colorGreyLight;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $colorGrey;
}

.miniPreviewImgPlug {
	background: $colorGrey;
}

.removeImg {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background: rgba(242, 243, 245, 0.7);
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	display: flex;
    align-items: center;
    justify-content: center;
	z-index: 9;
	color: $colorGreyLight;
}

.previewBody {
	width: 120px;
	height: 120px;
	overflow: hidden;
}

button.savePromp {
	color: $colorPrimary;
	position: relative;

	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -20px;
	}

	&:hover::after {
		background: rgba(0, 160, 130, 0.05);
	}
}

.previewSize {
	position: absolute;
    z-index: 1;
    left: 41px;
    bottom: -21px;
    font-weight: 400;
    font-size: 12px;
    color: #797A8D;
	&.previewSizeHeight {
		top: 50%;
		transform: rotate(-90deg)translate(6px, 12px);
		left: 50%;
		right: 0;
		width: 100%;
		bottom: auto;
		text-align: center;
	}
}
