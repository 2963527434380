@import '/src/static/styles/theme';

.root {
	color: $colorDark;
}

.title {
	font-weight: 600;
	font-size: 24px;
	line-height: 140%;
	text-align: center;
}

.ico {
	display: flex;
	justify-content: center;
	margin-top: 25px;
}

.description {
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	background: rgba(255, 243, 218, 0.7);
	border-radius: 8px;
	box-sizing: border-box;
	padding: 12px 16px;
	display: flex;
	align-items: flex-start;
	margin-top: 27px;


	a {
		color: $colorDark;

		&:hover {
			text-decoration: none;
		}
	}
}

.icoI {
	color: $colorSecondaryYellow;
	margin-right: 16px;
}

.backToLogin {
	margin: 20px auto 0;
	background: $colorPrimary;
	width: 200px;
	height: 45px;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 31px;
	color: $colorWhite;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
}
