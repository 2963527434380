@import 'static/styles/_theme.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url('/static/imgs/loaderBg.jpeg') no-repeat;
	background-size: cover;
	z-index: 1000;
}

.root {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.loaderText {
	font-weight: 400;
	font-size: 16px;
	margin-top: 20px;
}
