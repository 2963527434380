@import '/src/static/styles/theme';

.root {
	box-sizing: border-box;
	position: relative;
	width: 100%;
}

.label {
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
	padding: 0 18px;
	height: 40px;
	border-radius: 40px;
	outline: none;
	border: 1px solid $colorGreyLightTwo;
	box-sizing: border-box;
	font-weight: 600;
	color: $colorDark;
	max-width: 156px;
	position: relative;
	width: 100%;
	margin-left: 15px;

	&.error {
		border-color: rgba($colorError, 0.5);
	}

	&_title {
		font-weight: 500;
		font-size: 14px;
		color: $colorGreyLight;
	}
}

.addressList {
	width: 560px;
	display: flex;
	min-width: 220px;
	min-height: 116px;
	position: absolute;
	top: 45px;
	left: -50px;
	color: $colorGreyLight;
	background-color: $colorWhite;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;
	padding: 20px;
	z-index: 11;
	flex-direction: column;
	box-sizing: border-box;

	&_wrapper {
		display: flex;
		justify-content: space-between;

		svg {
			width: 16px;
			height: 16px;
			cursor: pointer;
			path {
				fill: $colorGreyLight;
			}
		}
	}

	&_title {
		font-weight: 600;
		font-size: 14px;
		color: $colorDark;
		margin-bottom: 7px;

	}

	&_info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-weight: 300;
		font-size: 12px;
		color: $colorGreyLight;
	}

	&_price {
		padding-right: 60px;
	}

	&_options {
		display: flex;
		flex-direction: column;
		margin-top: 7px;
		gap: 2px;
	}
}


.buttons {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
	padding: 0 12px;
}

.button {
	color: $colorGreyLight;
	font-weight: 500;
	cursor: pointer;
	font-size: 14px;
	line-height: 17px;
	color: $colorDark;

	position: relative;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 5px 6px;
		position: absolute;
		top: -5px;
		left: -6px;
	}
}

.confirm {
	color: $colorPrimary;

	&:hover::after {
		background: rgba(0, 160, 130, 0.05);
	}
}

.isDisableProduct {
	background: $colorGrey;
}

.miniLabel {
	position: absolute;
    background: #fff;
    font-size: 10px;
    top: 0;
    transform: translateY(-50%);
    right: 22px;
    padding: 0 4px;
    box-sizing: border-box;
	border-radius: 5px;
	color: $colorGreyLight;
	font-weight: normal;
	letter-spacing: 0.15px;
}

.labelWrap {
	display: flex;
	justify-content: flex-end;
}

.errRoot {
	width: 100%;
    max-width: 156px;
    margin-left: auto;
}
