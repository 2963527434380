@import '/src/static/styles/theme';

.root {
	background: $colorWhite;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;
	padding: 10px 0 15px;
	z-index: 11;
    position: relative;
}

.search {
	display: flex;
	align-items: center;
    height: 40px;
    border: 1px solid #E2E2E5;
    border-radius: 30px;
    outline: none;
    color: #493f58;
    font-size: 14px;
	font-weight: 100;
	width: calc(100% - 20px);
	margin: 0 auto;

	input {
		border: none;
	}

	&_icon {
		padding: 0.3rem 0 0 1rem;
	}
}

.lagsList {
	box-sizing: border-box;
	margin-top: 10px;
	overflow-y: auto;
    max-height: 185px;
}

.lagsListItem {
	margin-top: 5px;
	padding: 6px 20px;
	box-sizing: border-box;

	&:first-child {
		margin-top: 0;
	}

	&:hover {
		background: #F6F6F9;
	}
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10;
}
