@import 'static/styles/_theme.scss';

.root {
	background-color: transparent;
	border: 0;
	outline: 0;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s background-color;
	width: 40px;
	height: 40px;
	flex: 0 0 40px;
	cursor: pointer;
	text-decoration: none;
	&:not(:disabled):hover {
		background-color: rgba($colorLight, 0.1);
	}
	&:disabled {
		cursor: default;
		pointer-events: none;
	}
}

.average {
	width: 32px;
	height: 32px;
	flex: 0 0 32px;
}

.small {
	width: 24px;
	height: 24px;
	flex: 0 0 24px;
}
