@import '/src/static/styles/theme';

.root {
	margin-top: 40px;
}

.title {
	font-weight: bold;
	font-size: 16px;
}

.topBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.optionWrap {
	margin-top: 20px;
	border-radius: 20px;
	background: $colorWhite;
	padding: 20px 20px 22px;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);

	&.isModal {
		box-shadow: none;
	}
}

.attentionWrap {
	margin-left: 22px;
}

.parametersWrap {
	margin-top: 10px;
}

.parameter {
	padding-left: 10px;
	box-sizing: border-box;
}

.parameterTitle {
	font-weight: 500;
	font-size: 14px;
	margin-left: 12px;
	color: $colorDark;
}

.attentionParams {
	margin: 12px 0 0 12px;
}

.scopeWrap {
	margin-top: 12px;
	display: flex;
	align-items: flex-start;
	padding-left: 12px;
	box-sizing: border-box;
	align-items: center;
}

.scope {
	margin-left: 54px;
	&:first-child {
		margin-left: 0;
		position: relative;
		z-index: 2;
	}
}

.radioWrap {
	margin-top: 12px;
	display: flex;
	align-items: center;
}

.noMargin {
	margin: 0;
}

.list {
	margin-top: 20px;
}

.disabled {
	pointer-events: none;
}

.checkBoxWrap {
	margin-left: 65px;
}
