@import '/src/static/styles/theme';

.root {
	padding: 10px 20px 10px 5px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	&.disabled {
		.collectionImg {
			filter: grayscale(100%);
		}
		.collectionName {
			color: $colorGreyLight;
		}
	}
}

.leftBar {
	display: flex;
	align-items: center;
	min-width: 450px;
    margin-right: -90px;
}

.collapseSectionArr {
	margin-right: 14px;
	color: $colorGreyLight;
	pointer-events: all;
	cursor: pointer;
	min-width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	position: relative;

	&.rotate {
		transform: rotate(-90deg);
	}

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.collectionImg {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $colorGreyLightTwo;
	border-radius: 100%;
	color: #AEAEB8;
	margin-right: 11px;
	overflow: hidden;

	&.isNotValidCollection {
		position: relative;
		&::after {
		    content: "";
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			left: 0;
			top: 0;
			position: absolute;
			z-index: 8;
			background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
			border: 2px solid $colorError;
			pointer-events: none;
			box-sizing: border-box;
		}
		&::before {
			content: "!";
			font-size: 20px;
			color: #F65D3B;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 9;
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: none;
		}
	}

	img {
		width: 100%;
	}
}

.collectionName {
	font-weight: 700;
	font-size: 16px;
	margin-right: 12px;
	position: relative;
	span {
		position: absolute;
		top: 50%;
		left: -13px;
	}
}

.changeImg {
	font-size: 0;
	cursor: pointer;
	position: relative;

	&_edit {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 16px;
		height: 16px;
		padding: 7px;
		border-radius: 50%;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 100%;
			width: 38px;
			height: 38px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.centerBar {
	display: flex;
    justify-content: flex-start;
}

.rightBar {
	align-items: center;
	justify-content: flex-end;
	min-width: 360px;
	display: flex;

	svg {
		margin-right: 6px;
	}
}

.collectionRemove {
	margin: 0 25px;
	color: $colorGreyLight;
	cursor: pointer;
	padding-right: 3px;
	position: relative;
	display: flex;
	align-items: center;

	&.disabled {
		pointer-events: none;
		opacity: 0.6;
	}

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 38px;
		height: 38px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-left: -5px;
	}
}

.toggleActive {
	pointer-events: all;
}

.toggleDis {
	pointer-events: none;
	& > span { // styles for switch component
		background: rgba(121, 122, 141, 0.5);
		& > span {
			background: #E2E2E6;
    		box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
		}
	}
}

.promptWrap {
	position: absolute;
    width: 370px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    margin: 14px 0 0 -4px;
}

.collectionValid {
	background: none;
    transform: none;
}

.addSectionButtonWrap {
	position: relative;
	padding-left: 21px;
	box-sizing: border-box;
	margin: 0;
}

.addOpacity {
	opacity: 0;
	pointer-events: none;
}

.checkBoxWrap {
	position: absolute;
	right: calc(100% + 10px);
	top: 50%;
	transform: translateY(-50%);
}

.checkBoxIco {
	margin-right: 0;
	width: 15px;
	height: 15px;
	flex-basis: auto;

	svg {
		height: 15px;
	}
}
