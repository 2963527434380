@import '/src/static/styles/theme';

.ringContainer {
	width: 16px;
    height: 16px;
	position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
	pointer-events: none;
}

.circle {
    width: 16px;
    height: 16px;
    background-color: $colorPrimary;
    border-radius: 50%;
    position: absolute;
}

.ringring {
    border: 3px solid $colorPrimary;
	height: 28px;
    width: 28px;
    border-radius: 30px;
    position: absolute;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    left: -9px;
    top: -9px;
}

@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
