@import '/src/static/styles/theme';

.root {
	background: $colorWhite;
	width: 480px;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;

	&.isDescription {
		width: 735px;
	}
}

.header {
	padding: 19px 30px;
	box-sizing: border-box;
	font-weight: 500;
	font-size: 18px;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
}

.body {
	padding: 20px 30px 25px;
	box-sizing: border-box;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 25px;

	span {
		font-size: 12px;
		font-weight: 500;
		cursor: pointer;
		text-transform: uppercase;
		color: $colorDark;

		&.save {
			color: $colorPrimary;

			&.generateIsProgress {
				color: $colorGreyLight;
				pointer-events: none;
			}
		}
	}
}

.inputWrap {
	margin-top: 10px;

	&:first-child {
		margin-top: 0;
	}

	&.generateIsProgress {
		pointer-events: none;

		input {
			background: $colorGrey;
		}
	}
}

.shortLang {
	font-size: 12px;
	width: 30px;
	height: 30px;
	min-width: 30px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #D8D9E4;
	text-transform: uppercase;
	margin-right: 10px;
}

.defaultLanguage {
	display: flex;
	align-items: center;
}

.isDescriptionPopupLangs {
	overflow: visible;
}

.description {
	color: $colorDark;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 12px;
	margin: 0 auto 20px;
	border-radius: 8px;
	padding: 6px 12px;
	box-sizing: border-box;
	background: #EDF5FC;
	max-width: 420px;

	svg {
		width: 18px;
		color: #94ADC6;
		margin-right: 10px;
	}

	&.checkTranstalions {
		margin-top: 20px;
		background: rgba(255, 243, 218, 0.7);
		color: $colorGreyLight;

		svg {
			color: $colorSecondaryYellow;
		}
	}
}

.generateTranslateButton {
	font-weight: 500;
    font-size: 14px;
    display: flex;
    margin: 17px 0;
    justify-content: center;
	color: $colorDark;

	span {
		cursor: pointer;
		display: flex;
	}

	svg {
		color: $colorPrimary;
		margin-right: 7px;
	}

	&.generateIsProgress {
		pointer-events: none;
		color: $colorGreyLight;

		svg {
			color: $colorGreyLight;
		}
	}
}

.generateLoader {
	margin: 10px 0 -10px;
	display: flex;
	justify-content: center;
	img {
		width: 52px;
	}
}
