@import '/src/static/styles/theme';

.icon {
	color: $colorPrimary;
	margin-right: 5px;
}

.rootButton {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.name {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
}

.arr {
	margin-left: 9px;
}

.root {
	position: relative;
	color: $colorDark;
}

.tooltip {
	width: 421px;
	background: $colorWhite;
	box-shadow: -2px 2px 12px rgba(78, 69, 92, 0.2);
	border-radius: 20px;
	padding: 20px 25px 25px;
	box-sizing: border-box;
	position: absolute;
    z-index: 3;
    right: -80px;
	top: calc(100% + 10px);
}

.tooltipOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 2;
}

.tooltipTitle {
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 17px;
}

.addressesItem {
	margin-top: 19px;
	display: flex;
	align-items: flex-start;

	&:first-child {
		margin-top: 0;
	}
}

.iconInList {
	margin: 0 5px 0 -5px;
	width: 24px;
    min-width: 24px;
}

.storeAddressId {
	font-weight: 500;
	font-size: 14px;
	margin-right: 4px;
}

.address {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.01em;
	display: flex;
	align-items: flex-start;
	margin-top: 4px;
}
