@import '/src/static/styles/theme';


.wrapper {
	max-width: 100%;
	max-height: 100%;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $colorPrimaryYellow;
}

.imageSection {
	width: 60vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
}

.backgraund {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: auto;
}

.headrLine {
	padding-left: 3rem;
	padding-top: 3rem;
}

.imageContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0.8;
	color: $colorWhite;

	h1,
	p {
		font-weight: 400;
	}
}

.loginSection {
	width: 40vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: $colorWhite;
	box-shadow: -4px 4px 14px 0px #4E455C26;
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;

	h1 {
		text-align: center;
		margin: 0;
		padding: 0;
		color: $colorPrimary;
		font-weight: 500;
	}

	p {
		font-weight: 400;
		font-size: 1rem;
		text-align: center;
		color: $colorDark;
	}
}

.loginWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	width: 50%;
}

.inputSection {
	width: 100%;
	padding-top: 1.5rem;
	position: relative;



	span {
		position: absolute;
		top: 0;
		left: 1rem;
		font-size: 0.8rem;
		color: $colorGreyLight;
	}

	span:last-child {
		position: absolute;
		top: calc(50% + 0.8rem);
		left: 90%;
		transform: translate(0%, -50%);
		cursor: pointer;

		svg {
			height: 1rem;
			width: 1rem;
		}
	}
}

.checkboxSection {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;

	input {
		width: 1rem;
		height: 1rem;
		margin-left: 1rem;
		border: 2px solid $colorGreyLight;
		opacity: 0.7;
		border-radius: 3px;
		box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
		-webkit-appearance: none;
		outline: none;
	}

	input:checked {
		background: url('/static/imgs/checkIco.svg') no-repeat center/contain;
	}

	span {
		font-weight: 400;
		font-size: 0.8rem;
		color: $colorDark;
		padding-left: 0.5rem;
	}
}


.input {
	height: 40px;
	border: 1px solid $colorGreyLightThree;
	box-sizing: border-box;
	border-radius: 30px;
	color: $colorDark;
	padding: 0 19px;
	outline: none;
	width: 100%;

	&::placeholder {
		color: $colorGreyLight;
	}
}

.btn {
	background: $colorPrimary;
	width: 50%;
	padding: 0.8rem 5rem;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	color: $colorWhite;
	cursor: pointer;
	border: none;
	transition: background 1s ease;
	outline: none;
	white-space: nowrap;

	&:hover {
		transition: background 1s ease;
		background: #078b73
	}
}

.error {
	text-align: center;
	color: $colorGreyLight;
	font-size: 0.8rem;
}
