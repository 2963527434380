@import '/src/static/styles/theme';

.root {
	background: $colorWhite;
	padding: 28px 20px 20px;
	box-sizing: border-box;
}

.default {
	width: 541px;
}

.success {
	width: 435px;
}

.fail {
	width: 380px;
	.message {
		background: rgba(246, 59, 88, 0.1);;
	}
}

.title {
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	color: $colorDark;
}

.message {
	background: #EDF5FC;
	border-radius: 8px;
	text-align: center;
	padding: 12px 0;
	box-sizing: border-box;
	margin-top: 15px;
	font-weight: 400;
	font-size: 14px;
	color: $colorDark;
	line-height: 150%;
}

.textarea {
	margin-top: 20px;
	&::before,
	&::after {
		display: none!important;
	}
	textarea {
		height: 136px;
		font-weight: 600;
	}
}

.sendButton {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	button {
		background: $colorPrimary;
		box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
		border-radius: 21px;
		border: none;
		outline: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 42px;
		padding: 0 20px;
		box-sizing: border-box;
		color: $colorWhite;
		letter-spacing: 0.46px;
		font-weight: 500;
		font-size: 14px;
	}
	&.disabled {
		button {
			background: $colorGreyLight;
			box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
		}
	}
}
