$colorPrimary: #00A082;
$colorPrimaryHover: #00886F;
$colorPrimaryYellow: #FFC244;
$colorSecondaryYellow: #F1BF0E;
$colorGrey: #F2F3F5;
$colorGreyLight: #797A8D;
$colorGreyLightHover: #63647B;
$colorWhite: #ffffff;
$colorGreyLightTwo: #E2E2E5;
$colorGreyLightThree: #E2E2E6;
$colorGreyLightFour: #D8D9E4;
$colorLight: #9393a1;
$colorDark: #493f58;
$colorDarkOpacity: #493F580D;
$colorDarkHover: #78798c0d;
$colorError: #F65D3B;
$colorErrorLight: #FFF5F3;
$colorBlack: #000000;
