@import '/src/static/styles/theme';

.container {
	position: relative;
}

.addElementButton {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: $colorDark;
	white-space: nowrap;

	span {
		margin-right: 10px;
		color: $colorPrimary;
		font-size: 0;
	}

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(0, 160, 130, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 6px 8px 6px 10px;
		position: absolute;
		top: -6px;
		left: -10px;
	}
}

.icoWrap {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: calc(100% + 15px);
}

.promptWrap {
	width: 370px;
	position: absolute;
	top: calc(100% + 11px);
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}
