@import '/src/static/styles/theme';

.button {
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: $colorDark;

	span {
		width: 40px;
		height: 40px;
		background-color: $colorPrimaryYellow;
		border-radius: 100%;
		margin-right: 10px;
		color: $colorWhite;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&:hover span {
		background-color: $colorSecondaryYellow;
	}
}
