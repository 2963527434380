@import '/src/static/styles/theme';

.root {
	position: relative;
}

.errors {
	background-color: #fff;
	padding: 0 3px;
	color: $colorError!important;
	font-size: 10px;
	font-weight: normal;
	position: relative;
	width: fit-content;
	transform: translate3d(20px, -8px, 0);
	margin-bottom: -12px;
	max-width: calc(100% - 40px);
	white-space: nowrap;
}
