@import '/src/static/styles/theme';

.footer {
	background: rgba(226, 226, 230, 0.5);
	backdrop-filter: blur(8px);
	margin-top: 25px;
	padding: 9px 36px 9px 120px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	position: sticky;
	bottom: 0;
	z-index: 11;
}

.sendButton {
	width: 271px;
	height: 42px;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	color: $colorWhite;
	cursor: pointer;
	letter-spacing: 1px;

	&__asDraft {
		background: $colorGreyLight;
		&:hover {
			background: $colorGreyLightHover;
		}
		span {
			width: 23px;
		}
	}

	span {
		margin-right: 9px;
		display: flex;
		svg {
			width: 100%;
		}
	}

	&__toGlovo {
		background: $colorPrimary;
		position: relative;
		&:hover {
			background: $colorPrimaryHover;
		}

		span {
			color: $colorPrimaryYellow;
			width: 13px;
		}
	}

	&.isOptionsPage {
		margin-left: auto;
	}
}

.disabled {
	background: $colorGreyLight;
	pointer-events: none;
	&:hover {
		background: $colorGreyLight;
	}
	span {
		color: $colorWhite;
	}
	.sendingCount {
		background: #FFE2E2;
		color: $colorError;
	}
	&.enableClick {
		pointer-events: all;
	}
}

.sendingCount {
	color: $colorDark;
	font-weight: 500;
	font-size: 14px;
	width: 41px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
	border-radius: 20px;
	background: #CCECE6;
	position: relative;
	i {
		font-weight: 100;
		font-style: normal;
	}
	&:hover {
		&+.countNotification {
			display: block;
		}
		&::after {
			display: block;
		}
	}
	&::after {
		content: '';
		display: none;
		position: absolute;
		transform: rotate(-180deg) translateX(-50%);
		border: 6px solid transparent;
		border-bottom-color: #ffffff;
		bottom: calc(100% + 2px);
		right: 50%;
		z-index: 1;
	}
}

.oneToMaxSendingCount {
	.sendingCount {
		background: #FFF3DA;
	}
}

.saveAsDraft {
	display: flex;
	align-items: center;
}

.countNotification {
	display: none;
	position: absolute;
	background: #ffffff;
	pointer-events: none;
	padding: 10px 12px;
	box-sizing: border-box;
	font-weight: 100;
	font-size: 14px;
	color: #493f58;
	border-radius: 10px;
	white-space: nowrap;
	filter: drop-shadow(-2px 2px 12px rgba(78, 69, 92, 0.2));
	right: 0;
	bottom: calc(100% + 6px);
	z-index: 1;
}

.sendFeedback {
	position: absolute;
	bottom: calc(100% + 10px);
	right: 36px;
}
