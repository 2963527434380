@import '/src/static/styles/theme';

.attention {
	color: $colorGreyLight;
	display: flex;
	align-items: center;
	font-size: 12px;
	span {
		color: $colorGreyLight;
		margin-right: 7px;
		font-size: 0;
	}
}
