@import '/src/static/styles/theme';

.root {
	color: $colorDark;
}

.title {
	font-weight: bold;
	font-size: 14px;
}

.count {
	color: $colorGreyLight;
	font-size: 12px;
	margin-top: 5px;
}

.option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	margin-top: 20px;
	span {
		font-weight: 500;
		color: $colorPrimary;
	}
}

.changeButton {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 25px;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	position: relative;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 7px 10px;
		position: absolute;
		top: -6px;
		left: -7px;
	}

	svg {
		margin-right: 10px;
	}
}

.changeButtonWrap {
	display: flex;
	justify-content: center;
}
