@import '/src/static/styles/theme';

.root {
	position: relative;
	margin-left: auto;
	&.error {
		input {
			border-color: rgba($colorError, 0.5);
		}
	}
	input {
		width: 100%;
		max-width: 156px;
		height: 40px;
		border-radius: 40px;
		outline: none;
		display: block;
		border: 1px solid $colorGreyLightTwo;
		box-sizing: border-box;
		text-align: right;
		padding: 0 18px;
		font-weight: 600;
		color: $colorDark;
		&::placeholder {
			color: $colorGreyLight;
			font-weight: normal;
		}
	}
	span {
		color: $colorGreyLight;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		pointer-events: none;
		font-size: 0;
	}
}

.inner {
	position: relative;
}

.promptWrap {
	width: 250px;
	position: absolute;
	top: calc(100% + 11px);
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.label {
	position: absolute;
    font-size: 10px;
    top: 1px;
    transform: translateY(-50%);
    right: 23px;
    padding: 0 4px;
    box-sizing: border-box;
	border-radius: 5px;
	color: $colorGreyLight;
	letter-spacing: 0.15px;
	font-weight: normal;

	&::after {
		content: '';
		pointer-events: none;
		background: $colorWhite;
		width: 100%;
		height: 50%;
		position: absolute;
		left: 0;
		bottom: 2px;
		z-index: -1;
	}
}
