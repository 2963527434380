@import '/src/static/styles/theme';

.wrap {
	background: $colorWhite;
    width: 208px;
    box-shadow: -4px 4px 14px rgb(78 69 92 / 15%);
    border-radius: 20px;
    padding: 15px 20px 20px;
    position: absolute;
    left: 50%;
    top: calc(100% + 4px);
    transform: translateX(-50%);
    z-index: 5;
	cursor: default;
	box-sizing: border-box;
}

.menuLanguagesItem {
	margin-top: 10px;
	&:first-child {
		margin-top: 0;
	}
}

.manageLanguagesButton {
	margin-top: 17px;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	position: relative;

	span {
		color: $colorPrimary;
		margin-right: 7px;
		font-size: 0;
	}

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(0, 160, 130, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 6px 8px 6px 10px;
		position: absolute;
		top: -6px;
		left: -10px;
	}
}

.overlay {
	position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
	cursor: default;
}
