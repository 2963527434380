@import '/src/static/styles/theme';

.root {
	display: flex;
	align-items: center;
	white-space: nowrap;
	font-weight: 500;
	font-size: 14px;
	transition: all 0.5s ease;
	cursor: pointer;
	& > span {
		background: rgba(0, 160, 130, 0.3);
		width: 34px;
		height: 14px;
		margin-right: 12px;
		position: relative;
		border-radius: 14px;
		& > span {
			background: $colorPrimary;
			box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
			width: 20px;
			height: 20px;
			position: absolute;
			top: 50%;
			right: -2px;
			border-radius: 100%;
			transform: translateY(-50%);
		}
	}
	&.disable {
		pointer-events: all;
		& > span {
			background: rgba(121, 122, 141, 0.5);
			& > span {
				right: calc(100% - 20px + 2px ); // indent on the right - block width + indent outside the block borders
				background: #B3E3DA;
				box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
			}
		}
	}
	&.revert {
		flex-direction: row-reverse;
		span {
			margin-right: 0;
			margin-left: 20px;
		}
	}
}

.toggleActive {
	height: 30px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 0 10px;
	background: rgba(73, 153, 227, 0.1);
	border-radius: 25px;

}

.multilevel {
	height: 100%;
	border-radius: 15px;

	&_disabled {
		background-color: $colorDarkOpacity;
		svg {
			path {
				fill: #fff;
			}

			rect:first-child {
				fill: $colorGreyLightThree;
			}
		}
	}

}
