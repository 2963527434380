@import '/src/static/styles/theme';

.input {
	height: 40px;
	border: 1px solid $colorGreyLightThree;
	box-sizing: border-box;
	border-radius: 30px;
	color: $colorDark;
	padding: 0 19px;
	outline: none;
	width: 100%;
	&::placeholder {
		color: $colorGreyLight;
	}
}

.removeIco {
	padding: 0.1rem 1rem 0 0;
	cursor: pointer;
}
