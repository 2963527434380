@import '/src/static/styles/theme';

.root {
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;
	background: $colorWhite;
	width: 490px;
	padding-bottom: 35px;
	box-sizing: border-box;
}

.title {
	padding: 19px 30px;
	box-sizing: border-box;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	font-weight: 500;
	font-size: 18px;
}

.text {
	font-weight: 100;
	font-size: 14px;
	margin-top: 20px;
	text-align: center;
}

.menuItemsWrap {
	margin-top: 22px;
	display: flex;
	align-items: center;
	padding: 0 16px 0 24px;
	box-sizing: border-box;
	flex-wrap: wrap;
}

.menuItem {
	margin: 8px 8px 0 0;
}

.addLangButton {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 10px;
	position: relative;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px;
	margin-top: 25px;

	span {
		cursor: pointer;
		font-size: 12px;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 0.04em;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}

		&.save {
			color: $colorPrimary;

			&:hover::after {
				background: rgba(0, 160, 130, 0.05);
			}

			&.disable {
				pointer-events: none;
				color: $colorGreyLight;
			}
		}
	}
}

.popupLangContent {
	overflow: visible;
}

.selectDefaultLangWrap {
	padding: 0 24px;
	box-sizing: border-box;
	margin: 20px 0 0;
}

.areYouSure {
	font-weight: 600;
	font-size: 16px;
	color: $colorDark;
	text-align: center;
	margin-top: 20px;
}

.disableGenerationText {
	display: flex;
	width: calc(100% - 32px);
	margin: 17px auto 0;
	background: rgba(255, 231, 226, 0.7);
	border-radius: 8px;
	padding: 14px 40px 12px 15px;
	box-sizing: border-box;

	svg {
		width: 18px;
		min-width: 18px;
		margin-right: 15px;
		color: #F18360;
		margin-top: 3px;
		height: 15px;
	}

	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		color: #313131;
	}
}
