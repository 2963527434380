@import '/src/static/styles/theme';

.sortWrap {
	position: relative;
}

.sortIdentBody {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
    z-index: 9;
    right: 42px;
    top: 32px;
    width: 35px;
    height: 35px;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 38px;
		height: 38px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
