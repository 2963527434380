.root {
	display: flex;
	align-items: center;
}

.shortLang {
	font-size: 12px;
	width: 30px;
	height: 30px;
	min-width: 30px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #D8D9E4;
	text-transform: uppercase;
	margin-right: 10px;
}
