@import '/src/static/styles/theme';

.popupUploadImg {
	background: $colorWhite;
	border-radius: 20px;
	filter: drop-shadow(-4px 4px 14px rgba(78, 69, 92, 0.15));
	width: 780px;
}

.title {
	padding: 20px 56px 18px 30px;
	box-sizing: border-box;
	font-weight: bold;
	font-size: 18px;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.popupUploadBody {
	padding: 20px 30px 30px;
	box-sizing: border-box;
}

.uploadWrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	&.isMultiLang {
		.infoWrap {
			width: 300px;
		}
	}
}

.imgWrap {
	width: 300px;
	height: 300px;
	background: $colorGrey;
	border: 1px dashed $colorGreyLight;
	box-sizing: border-box;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: $colorDark;
	position: relative;
	overflow: hidden;
	span {
		color: $colorGreyLight;
		svg {
			width: 40px;
			height: 36px;
		}
	}
}
.imgWrapLoad {
	background:$colorWhite;
}

.text {
	text-align: center;
	color: $colorDark;
	font-size: 14px;
	margin-top: 12px;
	line-height: 24px;
}

.uploadButton {
	letter-spacing: 0.46px;
	font-weight: 500;
	font-size: 14px;
	color: $colorWhite;
	background: $colorPrimary;
	width: 180px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 21px;
	margin-top: 8px;
	cursor: pointer;

	&:hover {
		background: $colorPrimaryHover;
	}
}
.noneInput {
	display: none;
}
.loadedImg {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 10px;
}

.infoWrap {
	color: $colorDark;
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	margin-top: 10px;
	max-width: 300px;
	background: #EDF5FC;
	padding: 6px 12px;
	box-sizing: border-box;
	margin-left: auto;
	border-radius: 8px;
	line-height: 18px;
	span {
		font-weight: 500;
	}
	svg {
		width: 20px;
		height: 20px;
		min-width: 20px;
		margin-right: 7px;
		color: #94ADC6;
	}
	.infoWrapText {
		div {
			margin-bottom: 7px;
		}
	}
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 22px;

	button {
		background: none;
		border: none;
		outline: none;
		color: $colorGreyLight;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 12px;
		cursor: pointer;
		padding: 0;
		position: relative;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}
	}
}

.promptIco {
	position: absolute;
	top: 50%;
	left: 50%;
}

.error {
	width: 100% !important;
    text-align: center;
    margin: 10px auto 0;
    transform: none !important;
    background: none;
    white-space: normal !important;
    box-sizing: border-box;
}

.rootValidation {
	max-width: 300px;
}

.miniPreview {
    display: flex;
    align-items: center;
	position: relative;
	width: 120px;
}

.miniPreviewText {
	font-weight: 400;
	font-size: 14px;
	color: $colorDark;
}

.miniPreviewImg {
	width: 120px;
	height: 120px;
	border-radius: 10px;
	position: relative;
	border: 1px dashed $colorGreyLight;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $colorGrey;
}

.miniPreviewImgPlug {
	background: $colorGrey;
}

.previewBody {
	width: 120px;
	height: 120px;
	overflow: hidden;
}

.removeImg {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background: rgba(242, 243, 245, 0.7);
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	display: flex;
    align-items: center;
    justify-content: center;
	z-index: 9;
	color: $colorGreyLight;
}

button.savePromp {
	color: $colorPrimary;
	position: relative;

	span {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -20px;
	}

	&.generateIsProgress {
		color: $colorGreyLight;
		pointer-events: none;
	}

	&:hover::after {
		background: rgba(0, 160, 130, 0.05);
	}
}

.previewWrap {
	width: calc(100% - 300px - 20px); // 100% - imgBlockWidth - marginRight
	&.isMultiLang {
		.inputNameWrap {
			margin: 10px 0 0;
			display: flex;
			align-items: center;
		}
		.miniPreview {
			margin-bottom: 40px;
		}
	}
}

.collectionNameLabel {
	padding-left: 20px;
	box-sizing: border-box;
	color: $colorGreyLight;
	font-weight: 400;
	font-size: 12px;
}

.inputNameWrap {
	margin-top: 40px;

	input {
		font-weight: 700;
		font-size: 16px;
	}

	&.generateIsProgress {
		pointer-events: none;

		input {
			background: $colorGrey;
		}
	}
}

.toggleActive {
	pointer-events: all;
}

.defaultLang {
	color: $colorDark;
	background: #D8D9E4;
    font-weight: 300;
    font-size: 12px;
    width: 30px;
    height: 30px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
	margin-right: 10px;
	min-width: 30px;
}

div.redBorder {
	input {
		border-color: $colorError;
	}
}

.inputWrap {
	width: 100%;
}

.rootValid {
	width: 100%;
}

.previewSize {
	position: absolute;
    z-index: 1;
    left: 41px;
    bottom: -21px;
    font-weight: 400;
    font-size: 12px;
    color: #797A8D;
	&.previewSizeHeight {
		top: 50%;
		transform: rotate(-90deg)translate(8px, 12px);
		left: 50%;
		right: 0;
		width: 100%;
		bottom: auto;
		text-align: center;
	}
}

.isSuperCollection {
	.miniPreview {
		width: 257px;
		.miniPreviewImg {
			width: 257px;
			height: 143px;
			position: relative;
			&::after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(0deg, rgba(73, 63, 88, 0.3), rgba(73, 63, 88, 0.3));
			}
			.previewBody {
				width: 257px;
				height: 143px;
			}
		}
	}
	.previewSize {
		width: 100%;
		font-size: 14px;
		left: 0;
		text-align: center;
		&.previewSizeHeight {
			left: 50%;
		}
	}
	.previewSupCollectionTitle {
		position: absolute;
		font-weight: 600;
		font-size: 20px;
		color: $colorWhite;
		z-index: 1;
		text-align: center;
		padding: 0 5px;
		box-sizing: border-box;
	}
}

.description {
	color: $colorDark;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 10px;
	border-radius: 8px;
	padding: 6px 12px;
	box-sizing: border-box;
	background: #EDF5FC;

	svg {
		width: 18px;
		color: #94ADC6;
		margin-right: 10px;
	}

	&.checkTranstalions {
		margin-top: 20px;
		background: rgba(255, 243, 218, 0.7);
		color: $colorGreyLight;

		svg {
			color: $colorSecondaryYellow;
		}
	}
}

.generateTranslateButton {
	font-weight: 500;
    font-size: 14px;
    display: flex;
    margin: 17px 0;
    justify-content: center;
	color: $colorDark;

	span {
		cursor: pointer;
		display: flex;
	}

	svg {
		color: $colorPrimary;
		margin-right: 7px;
	}

	&.generateIsProgress {
		pointer-events: none;
		color: $colorGreyLight;

		svg {
			color: $colorGreyLight;
		}
	}
}

.generateLoader {
	margin: 10px 0 -10px;
	display: flex;
	justify-content: center;
	img {
		width: 52px;
	}
}
