@import '/src/static/styles/theme';

.root {
	margin: 0 10px 0 auto;
}

.icon {
	width: 24px;
	flex: 0 0 24px;
	color: $colorLight;
	cursor: pointer;
}
