@import '/src/static/styles/theme';

.scopeInputWrap {
	display: flex;
	align-items: center;

	span {
		white-space: nowrap;
		margin-left: 10px;
	}
}

.scopeInput {
	width: 100px;
	position: relative;
	input {
		text-align: right;
	}
}

.promptWrap {
	width: 325px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: calc(100% + 9px);
	z-index: 1;
}

.title {
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.01em;
	margin-right: 10px;
	color: $colorDark;
}

.description {
	color: $colorGreyLight;
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translate(12px, -50%);
	cursor: pointer;
	z-index: 1;
	font-size: 0;

	.descriptionText {
		background: $colorWhite;
		display: none;
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(10px, -50%);
		white-space: nowrap;
		border-radius: 10px;
		padding: 12px 10px;
		box-sizing: border-box;
		letter-spacing: 0.01em;
		color: $colorDark;
		font-weight: 400;
		font-size: 14px;
		filter: drop-shadow(-2px 2px 12px rgba(78, 69, 92, 0.2));
		pointer-events: none;

		&::after {
			content: "";
			position: absolute;
			transform: rotate(-90deg) translateX(-50%);
			border: 6px solid transparent;
			border-bottom-color: #ffffff;
			bottom: 50%;
			right: 100%;
			z-index: 1;
		}
	}

	&:hover .descriptionText {
		display: block;
	}
}

.errors {
	left: 0;
    transform: translateY(2px);
    padding: 0;
	width: 100px;
}
