@import '/src/static/styles/theme';

.sectionWrap {
	padding-top: 10px;
	box-sizing: border-box;
	div[data-id=promptWrap] {
		display: none;
	}
	&.isFirstSection {
		div[data-id=promptWrap] {
			display: block;
		}
	}
}
