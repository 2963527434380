@import '/src/static/styles/theme';

.productRemovePopup {
	padding: 40px 30px 30px;
	box-sizing: border-box;
	width: 480px;
	max-width: 100%;
	border-radius: 20px;
	background: $colorWhite;
	display: flex;
	flex-direction: column;
	align-items: center;
	filter: drop-shadow(-4px 4px 20px rgba(78, 69, 92, 0.15));
}

.title {
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	margin-top: 20px;
	font-weight: 600;
	color: $colorDark;
	padding: 0 30px;
}

.ico {
	margin-top: 1rem;
}

.text {
	margin-top: 15px;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $colorDark;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 30px;
}

.button {
	color: $colorGreyLight;
	font-weight: 500;
	cursor: pointer;
	font-size: 14px;
	line-height: 17px;
	color: $colorDark;
	position: relative;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 5px 6px;
		position: absolute;
		top: -5px;
		left: -6px;
	}
}

.confirm {
	color: $colorPrimary;

	&:hover::after {
		background: rgba(0, 160, 130, 0.05);
	}
}
