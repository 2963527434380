@import '/src/static/styles/theme';

.optionItem {
	padding: 10px 33px 10px 20px;
	box-sizing: border-box;
	position: relative;
	display: flex;
	align-items: center;

	&::after {
		content: '';
		display: block;
		width: calc(100% - 40px);
		height: 1px;
		background: $colorGreyLightThree;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
	}

	div[data-id=promptWrap] {
		display: none;
	}

	&:first-child {
		div[data-id=promptWrap] {
			display: block;
		}
	}
}

.inputWrap {
    width: 46%;
    max-width: 400px;
	position: relative;
}

.priceInput {
	width: 140px;
	margin-left: 10px;
	input {
		max-width: 100%;
	}
}

.switch {
	margin-left: auto;
}

.productRemove {
	margin: 0 0 0 23px;
	cursor: pointer;
	position: relative;
	color: $colorGreyLight;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 38px;
		height: 38px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.disabled {
	background-color: $colorGrey;
	transition: background 0.5s ease-out;
	pointer-events: none;
	.toggleActive {
		pointer-events: all;
	}
}

.disabledSwitch{
	background: rgba(121, 122, 141, 0.5)!important;
	& > span {
		background: #E2E2E6!important;
		box-shadow: 0px 2px 6px rgb(0 0 0 / 15%)!important;
	}
}

.titlesLangBarWrap {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
}
