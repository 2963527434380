@import '/src/static/styles/theme';

.userIco {
	margin-left: 1.25rem;
	margin-right: 5px;
	font-size: 0;
	cursor: pointer;
}

.root {
	display: flex;
	align-items: center;
}

.logoutHead {
	margin-right: 0.5rem;
	color: $colorDark;
	font-size: 14px;
    align-items: center;
    display: flex;
	font-weight: 500;
	padding: 3px 10px;
	box-sizing: border-box;
	border-radius: 15px;
	svg {
		margin-right: 10px;
		color: $colorGreyLight;
	}
	&:hover {
		background: rgba(121, 122, 141, 0.05);;
	}
}

.userModal {
	display: none;
	min-width: 250px;
	position: absolute;
	top: 70px;
	right: -21px;
	color: $colorGreyLight;
	background-color: $colorWhite;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 10px;
	margin-bottom: 3px;
	margin-top: 3px;
	z-index: 1;
	flex-direction: column;
	box-sizing: border-box;
	&::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border: 8px solid transparent;
		border-bottom-color: #fff;
		position: absolute;
		bottom: 100%;
		right: 30px;
		z-index: 1;;

	}
	&_open {
		display: flex;
	}
	&_mail {
		font-weight: 300;
		font-size: 14px;
		color: $colorDark;
		height: 40px;
		padding: 0 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
	}
	&_logout {
		display: flex;
		align-items: center;
		background: $colorGrey;
		height: 40px;
		padding: 0 20px 0 10px;
		box-sizing: border-box;
		border-radius: 0 0 10px 10px;
	}
}
