@import '/src/static/styles/theme';

.root {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay {
	background: $colorDark;
	opacity: 0.2;
	backdrop-filter: blur(8px);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.modal {
	z-index: 11;
	max-width: 90%;
	overflow: auto;
    max-height: 100%;
	position: relative;
}

.close {
	font-size: 0;
	color: #9394BD;
	width: 16px;
	height: 16px;
	position: absolute;
	top: 22px;
	right: 20px;
	cursor: pointer;
	z-index: 10;

	svg {
		width: 100%;
		height: 100%;
	}

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 100%;
		width: 38px;
		height: 38px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.content {
	position: relative;
	max-height: calc(100% - 40px); // top and bottom padding
	overflow-y: auto;
	border-radius: 20px;
}
