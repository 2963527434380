@import 'static/styles/theme';

.control {
	border: none;
	height: auto;
	position: relative;

	&:hover::after {
		content: "";
		display: block;
		pointer-events: none;
		background: rgba(121, 122, 141, 0.05);
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 5px 6px;
		position: absolute;
		top: -5px;
		left: -6px;
	}
}
