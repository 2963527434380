@import '/src/static/styles/theme';

.root {
	margin: 40px 0 140px;
}

.title {
	font-weight: bold;
	font-size: 16px;
	padding-left: 20px;
}

.topBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.optionWrap {
	margin-top: 20px;
	border-radius: 20px;
	background: $colorWhite;
	padding: 20px 10px 25px;
}

.attentionWrap {
	margin-left: 22px;
}

.parametersWrap {
	margin-top: 22px;
	display: flex;
	align-items: flex-start;
}

.parameter {
	width: 40%;
	padding-left: 10px;
	box-sizing: border-box;
	margin-left: 9%;
	&:first-child {
		margin-left: 0;
	}
}

.parameterTitle {
	font-weight: bold;
	font-size: 14px;
	margin-left: 12px;
}

.attentionParams {
	margin: 12px 0 0 12px;
}

.scopeWrap {
	margin-top: 12px;
	display: flex;
	align-items: flex-start;
}

.scope {
	margin-left: 67px;
	&:first-child {
		margin-left: 0;
	}
}

.radioWrap {
	margin-top: 12px;
	display: flex;
	align-items: center;
}

.noMargin {
	margin: 0;
}

.list {
	margin-top: 20px;
}
