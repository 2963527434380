@import '/src/static/styles/theme';

.root {
	padding: 20px 20px 40px;
	box-sizing: border-box;
	margin-top: 20px;
	background: linear-gradient(0deg, rgba(203, 230, 255, 0.2), rgba(203, 230, 255, 0.2)), #FFFFFF;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 20px;
}
