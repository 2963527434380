
.root {
	display: flex;
}

.content {
	box-sizing: border-box;
	padding: 0 36px 0 40px;
	min-height: calc(100vh - 85px);
	flex-grow: 1;
}
