@import '/src/static/styles/theme';

.root {
	background: #F6F6F9;
	padding: 5px 32px 5px 5px;
	box-sizing: border-box;
	border-radius: 20px;
	position: relative;
	&.isDefault {
		background: #4999E3;
		padding-right: 44px;
	}
}

.closeIco {
	color: $colorGreyLight;
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.default {
	color: #CBE6FF;
	font-size: 10px;
	position: absolute;
	top: 4px;
	right: 7px;
}
