@import '/src/static/styles/theme';

.root {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	color: $colorDark;
	&.active {
		span {
			background: #4999E3;
			color: $colorWhite;
		}
	}
	&.insert {
		color: $colorWhite;
		span {
			color: $colorDark;
			background: $colorGrey;
			font-weight: 500;
		}
	}
	span {
		background: #D8D9E4;
		font-weight: 500;
		font-size: 12px;
		width: 30px;
		height: 30px;
		margin-right: 7px;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
	}
}
