@import '/src/static/styles/theme';

.root {
	color: $colorDark;
	display: flex;
	align-items: center;
}

.button {
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: $colorDark;

	svg {
		margin-right: 8px;
		color: $colorPrimary;
	}

	&:hover {
		color: #000000;

		svg {
			color: $colorPrimaryHover;
		}
	}
}

.lastSync {
	margin-left: 20px;
	color: $colorGreyLight;
	letter-spacing: 0.01em;
	font-size: 14px;
	font-weight: 500;
}
