@import '/src/static/styles/theme';

.selectDefaultLangWrap {
	height: 40px;
	border: 1px solid #D8D9E4;
	border-radius: 20px;
	color: $colorGreyLight;
	display: flex;
	align-items: center;
	padding: 0 48px 0 23px;
	box-sizing: border-box;
	cursor: pointer;
	font-size: 14px;
	position: relative;

	.arr {
		position: absolute;
		top: 50%;
		right: 17px;
		transform: translateY(-50%);
	}

	&.disable {
		pointer-events: none;
	}
}

.langDropDownWrap {
	position: absolute;
	top: calc(100% + 5px);
	left: 0;
	width: 100%;
}

.root {
	position: relative;
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 35px;
	span {
		cursor: pointer;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 12px;
		letter-spacing: 0.04em;
		&.save {
			color: $colorPrimary;
			&.disabled {
				color: $colorGreyLight;
				pointer-events: none;
			}
		}
	}
}

.warningText {
    display: flex;
    margin-top: 20px;
    background: #F5FAFF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16px;
    color: #493F58;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}

.infoIco {
	color: #94ADC6;
	margin-right: 16px;
}

.disableGenerationText {
	display: flex;
	width: calc(100% - 32px);
	margin: 17px auto 0;
	background: rgba(255, 231, 226, 0.7);
	border-radius: 8px;
	padding: 14px 40px 12px 15px;
	box-sizing: border-box;

	svg {
		width: 18px;
		min-width: 18px;
		margin-right: 15px;
		color: #F18360;
		margin-top: 3px;
		height: 15px;
	}

	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		color: #313131;
	}
}
