@import '/src/static/styles/theme';

.root {
	border-bottom: 1px solid $colorGreyLightThree;
	box-sizing: border-box;

	&.onlyCollections {
		margin-left: 25px;
	}
}

.children {
	padding-left: 30px;
	box-sizing: border-box;
}
