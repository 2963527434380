@import '/src/static/styles/theme';

.promptView {
	width: 100%;
	background: rgba(0, 160, 130, 0.75);
	backdrop-filter: blur(6px);
	border-radius: 10px;
	padding: 20px;
	box-sizing: border-box;
	position: relative;
	color: $colorWhite;
	&::after {
		content: '';
		display: block;
		border: 10px solid transparent;
		border-bottom-color: $colorPrimary;
		width: 0;
		position: absolute;
		left: 50%;
		bottom: 100%;
		opacity: 0.75;
		transform: translateX(-50%);
	}
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.titleText {
		font-weight: bold;
		font-size: 14px;
	}
	.close {
		width: 38px;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0);
		cursor: pointer;
		& svg {
			width: 12px;
			height: 12px;
			color: #fff;
		}
		&:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}
}

.body {
	margin-top: 12px;
	display: flex;
	color: $colorWhite;
	svg {
		width: 20px;
		min-width: 20px;
	}
}

.text {
	font-size: 12px;
	line-height: 16px;
	color: $colorGrey;
	margin-left: 7px;
	margin-top: 2px;
}

.examples {
	background: $colorPrimary;
	border-radius: 2px;
	padding: 9px 20px;
	box-sizing: border-box;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.example {
	display: flex;
	flex-direction: column;
	align-items: center;
	span {
		margin-top: 6px;
		font-size: 12px;
	}
	svg {
		color: white;
	}
}
