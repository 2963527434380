@import '/src/static/styles/theme';

.container {
	display: block;
    width: 100%;
	min-width: 350px;
	position: relative;
}

.valueContainer {
    border: 1px solid $colorGreyLight;
    padding:  0.66rem 1.25rem;
    font-size: 14px;
    border-radius: 30px;
    color: $colorDark;
	background-color: $colorWhite;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	&_info {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&_id {
		font-weight: 500;
	}

	&_name {
		font-weight: 300;
	}

	&_arrow {
		transform: rotate(180deg);
		transition: transform 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
	}
}

.listContainer {
	display: none;
	width: 100%;
	position: absolute;
	background-color: $colorWhite;
	box-shadow: -4px 4px 14px rgba(78, 69, 92, 0.15);
	border-radius: 20px;
	margin-bottom: 3px;
	margin-top: 3px;
	z-index: 1;
	flex-direction: column;
	padding: 0.5rem 0 20px 0;
	box-sizing: border-box;
	max-height: 420px;
	overflow-y: auto;

	&_open {
		display: flex;
	}

	&_store {
		&:hover {
			background: $colorGrey;
			color: $colorDark;
		}
	}
}

.search {
	display: flex;
    width: 90%;
	align-items: center;
    height: 40px;
    border: 1px solid #E2E2E5;
    border-radius: 30px;
    outline: none;
    color: #493f58;
    font-size: 14px;
	font-weight: 400;
	margin: 5px auto 5px auto;

	input {
		border: none;
	}

	&_icon {
		padding: 0.3rem 0 0 1rem;
	}
}
