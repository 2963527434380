@import '/src/static/styles/theme';

.search {
	display: flex;
    width: 100%;
	align-items: center;
    height: 40px;
    border: 1px solid #D8D9E4;
	border-radius: 20px;
    outline: none;
    color: $colorGreyLight;
    font-size: 14px;
	font-weight: 400;

	input {
		border: none;
		padding-left: 12px;
	}

	&_icon {
		padding: 0.3rem 0 0 20px;
	}

	&::placeholder {
		color: red;
	}
}

.emptySearch {
	position: absolute;
	top: calc(100% + 4px);
	background: $colorWhite;
	height: 52px;
	width: 100%;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 20px;
	display: flex;
	align-items: center;
	padding-left: 30px;
	box-sizing: border-box;
	left: 0;
	color: $colorDark;
	font-weight: 400;
	font-size: 14px;

	svg {
		color: $colorGreyLight;
		margin-right: 9px;
	}
}
