@import '/src/static/styles/theme';

.defaultLangWrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	font-weight: 400;
	font-size: 14px;
	color: $colorDark;
	margin-right: 20px;
}

.renderLangWrap {
	border: 1px solid $colorGreyLightFour;
	border-radius: 40px;
	padding: 5px 16px 5px 5px;
	box-sizing: border-box;
	pointer-events: none;
}

.warningText {
    display: flex;
    margin-top: 20px;
    background: rgba(255, 243, 218, 0.7);;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16px;
    color: #493F58;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}

.infoIco {
	color: $colorSecondaryYellow;
	margin-right: 16px;
}

.buttons {
	margin-top: 37px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	span {
		text-transform: uppercase;
		color: $colorDark;
		cursor: pointer;
		font-weight: 500;
		font-size: 12px;
		letter-spacing: 0.04em;
		&.yes {
			color: $colorPrimary;
		}
	}
}
