@import '/src/static/styles/theme';

.wrapper {
	position: sticky;
	top: 0;
	z-index: 12;
	background-color: $colorGrey;
	border-radius: 0 0 20px 20px;
}

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	box-sizing: border-box;
	padding: 20px 0;
}

.title {
	color: $colorDark;
	font-weight: bold;
	font-size: 24px;
	padding-left: 20px;
}

.centrText {
	color: $colorPrimary;
	font-weight: bold;
	font-size: 16px;
	text-transform: uppercase;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.syncWithPartnerWrap {
	margin-left: 32px;
}

.topSettings {
	display: flex;
}

.searchWrap {
	margin-left: 8px;
	background: $colorWhite;
	width: 412px;
	min-width: 412px;
	display: flex;
	align-items: center;
	box-shadow: -2px 2px 8px rgba(78, 69, 92, 0.08);
	border-radius: 20px;
	padding: 0 10px;
	box-sizing: border-box;
	position: relative;
}

.leftBar {
	width: 100%;
}
