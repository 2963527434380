@import '/src/static/styles/theme';

.inputWrap {
	position: relative;
	width: 100%;
	$wrap: &;
	&.error {
		input, textarea {
			border-color: rgba($colorError, 0.5);
		}
	}

	input {
		display: block;
		width: 100%;
		height: 40px;
		border: 1px solid $colorGreyLightTwo;
		box-sizing: border-box;
		border-radius: 30px;
		outline: none;
		padding: 0 55px 0 20px;
		color: $colorDark;
		font-size: 14px;
		font-weight: 600;
		&::placeholder {
			color: $colorGreyLight;
			font-weight: normal;
		}
	}

	textarea {
		display: block;
		width: 100%;
		height: 96px;
		border: 1px solid $colorGreyLightTwo;
		box-sizing: border-box;
		border-radius: 20px;
		outline: none;
		padding: 12px 50px 12px 23px;
		color: $colorDark;
		font-size: 14px;
		resize: none;
		&::placeholder {
			color: $colorGreyLight;
			font-weight: normal;
		}
	}

	&.showLangBarInput {
		input {
			padding-right: 90px;
		}
	}

	&_tooltip {
		&#{$wrap} {
			overflow-wrap: anywhere;

			&:hover::before {
				box-sizing: inherit;
				content: attr(data-value);
				padding: 12px 20px;
				gap: 8px;
				width: calc(100% - 40px);
				background: $colorWhite;
				border-radius: 10px;
				font-weight: 300;
				font-size: 16px;
				display: flex;
				align-items: center;
				top: 50px;
				left: 0px;
				align-items: center;
				color: $colorDark;
				position: absolute;
				box-shadow: -2px 2px 8px 0px #4E455C29;
				z-index: 11;
				overflow-wrap: anywhere;
			}

			&:hover::after {
				content: '';
				position: absolute;
				top: 50px;
				left: 50%;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 9px 8px 9px;
				z-index: 1111;
				border-color: transparent transparent $colorWhite transparent;
				transform: translate(0,-100%);
			}
		}
	}

	.allowedNumberWrap {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		&.isTextarea {
			top: 12px;
			right: 18px;
			transform: none;
		}
		&.toBottom {
			top: auto;
			bottom: 10px;
		}
	}

	.allowedNumber {
		color: $colorGreyLight;
		font-size: 10px;
		&.showLangBar {
			margin-right: 30px;
		}
	}
}

.inner {
	position: relative;
}

.promptWrap {
	position: absolute;
	width: 370px;
	left: 50%;
	top: calc(100% + 10px);
	transform: translateX(-50%);
	z-index: 5;
}

.errorText {
	padding-left: 20px;
	margin-top: 5px;
}

.disabled {
	.inner {
		input {
			color: $colorGreyLight;
		}
	}
}

.label {
	position: absolute;
    font-size: 10px;
    top: 0;
    transform: translateY(-50%);
    left: 22px;
    padding: 0 4px;
    box-sizing: border-box;
	border-radius: 5px;
	color: $colorGreyLight;
	letter-spacing: 0.15px;
	font-weight: normal;

	&::after {
		content: '';
		pointer-events: none;
		background: $colorWhite;
		width: 100%;
		height: 50%;
		position: absolute;
		left: 0;
		bottom: 1px;
		z-index: -1;
	}
}
