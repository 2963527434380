@import 'static/styles/theme';

.root {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
}

.add {
	background: url('/static/imgs/plusIco.svg') no-repeat;
	background-position: left center;
	margin-left: 23px;
	padding: 15px 0;

	button {
		background: none;
		outline: none;
		border: none;
		padding-left: 28px;
		font-size: 14px;
		font-weight: 500;
		color: $colorDark;
		cursor: pointer;
		position: relative;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(0, 160, 130, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 6px 8px 6px 10px;
			position: absolute;
			top: -6px;
			left: -10px;
		}
	}
}

.buttons {
	padding: 6px 20px 30px 23px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    box-sizing: border-box;

	button {
		color: $colorGreyLight;
		background: none;
		border: none;
		text-transform: uppercase;
		cursor: pointer;
		font-weight: 500;
		font-size: 12px;
		position: relative;

		&:hover::after {
			content: "";
			display: block;
			pointer-events: none;
			background: rgba(121, 122, 141, 0.05);
			border-radius: 15px;
			width: 100%;
			height: 100%;
			padding: 5px 6px;
			position: absolute;
			top: -5px;
			left: -6px;
		}

		&.apply {
			color: $colorPrimary;
			&:hover::after {
				background: rgba(0, 160, 130, 0.05);
			}
		}
	}
}

.emptyOptionsText {
	font-weight: 400;
	font-size: 14px;
	padding: 0 20px 32px 13px;
	box-sizing: border-box;
	line-height: 17px;
	margin-top: 6px;
	color: $colorDark;
	.add {
		margin-left: 8px;
		padding: 0;
		margin-top: 22px;
	}
}

.toOptionPage {
	display: block;
	text-align: center;
	margin-top: 27px;
	color: $colorPrimary;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	font-size: 12px;
}

.controls {
	background-color: #ffffff;
	padding-bottom: 60px;
}
