@import '/src/static/styles/theme';

.root {
	margin-left: 20px;
	display: flex;
	align-items: center;
	span {
		font-weight: 500;
	}
}

.text {
	font-size: 14px;
	line-height: 16px;
	color: $colorDark;
	span {
		font-weight: 500;
	}
}

.icon {
	color: $colorPrimary;
	margin-right: 5px;
}
